import { Button, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared/components/styles';
import { ProjectStatusEnum } from 'src/shared/enums/projectStatus.enum';

type SustainabilitySubmitButtonProps = {
  status: number;
  disabled: boolean;
  updateDisabled: boolean;
  showDisabledTooltip: boolean;
  onClickDelete: () => void;
  onClickUpdate: () => void;
};

export const SustainabilitySubmitButton = (
  props: SustainabilitySubmitButtonProps,
) => {
  const {
    status,
    disabled,
    updateDisabled,
    showDisabledTooltip,
    onClickDelete,
    onClickUpdate,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {(status === ProjectStatusEnum.Created ||
        status === ProjectStatusEnum.SupplementRequested) && (
        <Grid
          item
          xs={12}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          p={2}
        >
          <Tooltip title={`${t('coomingSoon')}`} arrow>
            <span>
              <Button
                variant='outlined'
                color='warning'
                disabled
                // disabled={userDetails?.permissionGroup?.id !== 5}
                onClick={onClickDelete}
              >
                {t('Delete')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              updateDisabled
                ? `${t('noPermissionToEditReport')}`
                : `${t('onlyContentUpdate')}`
            }
            arrow
          >
            <span>
              <Button
                variant='outlined'
                type='button'
                disabled={updateDisabled}
                onClick={() => onClickUpdate()}
              >
                {t('saveChanges')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              showDisabledTooltip
                ? `${t('noPermissionToFinalizeReport')}`
                : `${t('emailToResponsibleReviewer')}`
            }
            arrow
          >
            <span>
              <Button
                variant='contained'
                sx={{
                  fontSize: 12,
                  backgroundColor: Colors.BADGE_YELLOW,
                  color: 'text.primary',
                  ':hover': {
                    backgroundColor: Colors.BADGE_YELLOW,
                    color: 'text.primary',
                  },
                }}
                type='submit'
                disabled={disabled}
              >
                {t('markAsReadyForApproval')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};
